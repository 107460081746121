<script setup>
import texts from "~/configs/texts";

const calculator = useCalculator();
const route = useRoute();
const router = useRouter();
const props = defineProps({
  car: Object,
  installment: [String, Number],
  unavailable: Boolean,
  bg: [Object, String]
})

const stayOpened = ref(false);
const drawerOpened = ref(false);
const unPin = () => {
  stayOpened.value = false;
  drawerOpened.value = false;
}

const defaultData = {
  phone: null,
  agr_p: false
}

const refStateBlend = ref();
const contact = useContact();

const formData = ref({...defaultData});
const errors = ref({...defaultData})

const clearForm = () => {
  formData.value = {...defaultData};
  errors.value = {...defaultData};
}

const validateAndSend = async () => {
  errors.value = {...defaultData}
  let passed = true;

  if (!formData.value.phone || !validatePhone(formData.value.phone)) {
    errors.value.phone = "Wprowadź poprawny numer telefonu";
    passed = false;
  }

  if (!formData.value.agr_p) {
    errors.value.agr_p = "To pole jest wymagane";
    passed = false;
  }

  if (props.car) {
    formData.value.brand = props.car.brandName;
    formData.value.model = props.car.modelName;
    formData.value.body = props.car.bodyType;
    formData.value.product_id = null;
  }

  formData.value.formType = 'samochody_oferta_indywidualna';

  if (passed) {
    refStateBlend.value.setBusy();

    const result = await contact.submit(formData)
    if (!result) {
      refStateBlend.value.setFailure();
    } else {
      refStateBlend.value.setSuccess();
      clearForm();
    }
  }
}

const destLink = computed(() => {
  if (!props.car || props.unavailable) return null;
  return {name: 'cars', hash: toLowerDashed('#' + (props.car?.bodyType || props.car?.bodyTypes.join(","))), params: toLowerDashed({brand: props.car?.brandName, model: props.car?.modelName})};
})

const vatValue = 1.23;
const price = computed(() => props.car?.price * vatValue);
const basicPrice = computed(() => props.car?.basic * vatValue);
const installment = computed(() => props.car?.installment * vatValue);

const isDragging = useState('slideControlDragging', () => false)

const dataLayer = ref([])

onMounted(() => {
  if(!props.car?.installment) calculator.getInstallment({data: props.car}, true)
  dataLayer.value = {
    event: "select_item",
    page_type: route.name,
    brand: props.car?.brandName,
    model: props.car?.modelName,
    link_url: router.resolve(destLink.value)?.fullPath,
    is_graphic_box: !!props.bg?.img
  }
})

</script>

<template>
  <div class="group relative flex flex-col w-full min-w-[300px] h-[300px]
              rounded-lg shadow-box hover:shadow-box-hover overflow-hidden select-none
              transition-all duration-300 hover:-translate-y-1 "
       :class="{'shadow-box-hover -translate-y-1':stayOpened,'text-white bg-center': !!bg}"
       :style="{backgroundImage: !!bg ? `url(${bg.img})` : 'none'}"
       v-click-outside="unPin">
    <NuxtLinkConditional :to="destLink" rel="nofollow" @dragstart.prevent="" :can="!isDragging" :dataLayer="dataLayer">
      <CarImageBox :car="car" v-if="!bg" />
      <div v-else class="relative h-[165px] select-none pointer-events-none overflow-hidden"></div>
      <div class="relative flex flex-col justify-center px-4 py-2.5 h-[135px] overflow-hidden"
           :class="[{'bg-white':!!!bg}]">
        <div class="transition-all" :class="{'opacity-0 scale-95': stayOpened, 'group-hover:opacity-0 group-hover:scale-95 duration-700':unavailable}">
          <div :class="[{'invisible':!!bg}]">
            <div class="text-sm font-extralight">
              {{ car?.brandName }}
            </div>
            <div class="flex justify-between items-baseline">
              <div class="text-xl leading-5">{{ car?.modelName }}</div>
              <div class="text-sm font-extralight">{{ (props.car?.bodyType || props.car?.bodyTypes.join(",")) }}</div>
            </div>
            <div class="border-t border-t-gray-400 mt-2 pt-2"></div>
          </div>
          <div v-if="!unavailable">
            <div class="flex justify-between items-baseline text-[10px] font-extralight leading-3">
              <div class="line-through">
                <span v-if="basicPrice > price">od {{ basicPrice ? priceFormat(basicPrice) : "?" }} zł</span>
              </div>
              <div>rata/mies.</div>
            </div>
            <div class="flex justify-between items-baseline text-sm font-extralight">
              <div>
                <span>od </span>
                <span class="text-2xl font-normal leading-6">{{ price ? priceFormat(price) : "?" }}</span>
                <span> zł</span>
              </div>
              <div :class="[{'text-azure': !!!bg,'text-white': !!bg}]">
                <template v-if="installment">
                <span class="text-2xl font-normal leading-6">{{ priceFormat(installment) }}</span>
                <span> zł</span>
                </template>
                <template v-else>
                  <div class="bg-gray-100 rounded animate-pulse w-16 leading-6 text-2xl font-normal">&nbsp;</div>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="font-extralight">
            <div>Zamów ofertę indywidualną</div>
            <div> {{ car?.brandName }} {{ car?.modelName }}</div>
          </div>
        </div>
      </div>
    </NuxtLinkConditional>
    <div class="absolute h-[135px] left-0 right-0 bottom-[-135px] bg-white transition-all duration-300"
         :class="{'group-hover:bottom-0':unavailable}">
      <div class="flex flex-col gap-2 px-4 justify-center h-full">
        <ButtonRounded v-if="car"
                       color="transparent"
                       :href="{name:'model', hash: toLowerDashed('#'+car?.bodyType), params: toLowerDashed({brand: car?.brandName, model: car?.modelName})}">Poznaj model
        </ButtonRounded>
        <ButtonRounded @click="stayOpened = true">Zamów ofertę indywidualną</ButtonRounded>
      </div>
    </div>
    <div v-if="unavailable" class="absolute h-[135px] left-0 right-0 bottom-[-135px] bg-white transition-all duration-300" :class="{'!bottom-0':stayOpened}">
      <FormStateBlend ref="refStateBlend" />
      <div class="flex flex-col gap-2 px-4 justify-center h-full">
        <div>
          <InputText type="tel" label="Numer telefonu"
                     @focus="stayOpened = true"
                     v-model="formData.phone" :error="errors.phone"
          />
        </div>
        <div class="flex">
          <div class="grow">
            <InputCheckBox @change="(checked) => formData.agr_p = checked" :error="errors.agr_p" :checked="formData.agr_p" wrap>{{ texts.form.agreements.phone.small }}
              <span @click="drawerOpened = true" class="cursor-pointer">{{ texts.form.agreements.phone.more }}</span>
            </InputCheckBox>
          </div>
          <div>
            <ButtonRounded @click="validateAndSend" fit>Wyślij</ButtonRounded>
          </div>
        </div>
      </div>
    </div>
    <div v-if="unavailable"
         class="absolute w-full h-full -bottom-full bg-white transition-all duration-300 ease-out z-20" :class="{'!bottom-0': drawerOpened}">
      <div class="absolute right-2 top-2 cursor-pointer" @click="drawerOpened = false">
        <IconClose />
      </div>
      <div class="p-4 pt-11 font-extralight text-xs">
        {{ texts.form.agreements.phone.large }}
      </div>
    </div>
  </div>
</template>
